import React, { Fragment } from "react"
import { Link } from "gatsby"
import Media from 'react-media'
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <Media queries={{
      small: "(max-width: 599px)",
      medium: "(min-width: 600px) and (max-width: 1199px)",
      large: "(min-width: 1200px)"
    }}>
      {matches => (
        <Fragment>
          {matches.small && <p>I am small!</p>}
          {matches.medium && <p>I am medium!</p>}
          {matches.large && <p>I am large!</p>}
        </Fragment>
      )}
    </Media>
    <Link to="/page-2/">Go to page 2</Link>
  </Layout>
)

export default IndexPage
